// import React from "react";
// import { ImSpinner } from "react-icons/im";
// import { BsCircleFill } from "react-icons/bs";
// import { MdDone } from "react-icons/md";

// const Steppers = ({ status, notified }) => {
//   console.log(status, notified, "===================status");
//   const renderIcon = (stepStatus, stepNumber) => {
//     if (stepStatus === "Complete") {
//       return <MdDone />;
//     } else if (stepStatus === "nothing") {
//       return <BsCircleFill />;
//     } else {
//       return <ImSpinner />;
//     }
//   };

//   return (
//     <div className="container">
//       <div className="ferozibg mt-3 mb-3">
//         <div className="texts d-flex justify-content-between align-items-center mb-3 inter">
//           <div className="ps">
//             <p
//               className={`font-10 fw-medium mb-0 ${status === "linked" ? "active" : "gray"
//                 }`}
//             >
//               Linked
//             </p>
//           </div>
//           <div className="ps">
//             <p
//               className={` font-10 mb-0 ${status === "Pending" ? "active font-14" : "gray"
//                 }`}
//             >
//               Preparing
//             </p>
//           </div>
//           <div className="ps">
//             <p
//               className={` font-10 fw-medium mb-0 ${status === "Notified" ? "active font-14" : "gray"
//                 }`}
//             >
//               Ready
//             </p>
//           </div>
//           <div className="ps">
//             <p
//               className={`font-10 fw-medium mb-0 ${status?.includes('Complete') ? "active font-14" : "gray"
//                 }`}
//             >
//               Picked up
//             </p>
//           </div>
//         </div>
//         <div className="padding-div position-relative">
//           {/* Linked */}
//           <div className="line">
//             <div
//               className={`bg-blue position-relative z-3`}
//             >
//               {renderIcon("Complete", 1)}
//             </div>
//           </div>
//           {/* Preparing */}
//           <div
//             className={`bg-blue  position-relative z-3 ${notified == 1 ? "" : "myloader"
//               } ${status === "Pending" ? "scale-up" : ""}`}
//           >
//             {notified == 1 ? <MdDone /> : renderIcon("Pending", 2)}
//           </div>
//           {/* Ready */}
//           <div
//             className={`bg-blue position-relative z-3
//              ${notified == 1 ? "" : "myloader"} ${status === "Notified" ? "scale-up" : ""
//               }`}
//           >
//             {/* {status === 'pickedup' ?  <ImSpinner /> : renderIcon('completed', 3)} */}
//             {notified == 1 ? (
//               renderIcon("Complete", 2)
//             ) : (
//               <BsCircleFill size={10} />
//             )}
//             {/* <BsCircleFill size={10}/> */}
//           </div>
//           {/* Picked Up */}
//           <div
//             className={`bg-blue position-relative z-3 ${status === "Complete" ? "scale-up " : "myloader"
//               }`}
//           >
//             {status === "Complete" ? (
//               <MdDone size={17} />
//             ) : status === "Notified" ?  (
//               <ImSpinner />
//             ) : (<BsCircleFill size={10} />)}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Steppers;
import React from "react";
import { HiCheckBadge } from "react-icons/hi2";
import { FaCircle } from "react-icons/fa";
import { MdTimelapse } from "react-icons/md";
import bgImg from "../assets/images/Line-progress.png";
const Steppers = ({ status }) => {
  // linked, Pending,Notified,Complete
  const statuses = ["Linked", "Preparing", "Ready", "Picked Up"];
  const currentStatus =
    status === "linked"
      ? "linked"
      : status === "Pending"
      ? "Pending"
      : status === "Notified"
      ? "Ready"
      : status === "Complete"?"Picked Up":"";
  return (
    <div className="flex items-center justify-center gap-3 mt-4 bg-gray-100">
      {statuses.map((status, index) => {
        const isCurrent = status === currentStatus;
        const isPrevious = statuses.indexOf(currentStatus) > index;

        return (
          <div
            key={status}
            className={`flex flex-col items-center relative  ${
              isCurrent && "flex-1"
            }`}
          >
            {/* Circle and Line */}
            <div className={` flex items-center justify-center`}>
              {isCurrent && (
                <>
                  <span className="absolute -top-[35px] z-10 bg-customBlue text-white p-1.5 rounded-xl px-3 text-sm  whitespace-nowrap	">
                    {status}
                  </span>
                  <span className="absolute left-[50%] right-[50%] -translate-x-2/4	 z-0 -top-2 w-0 h-0 border-l-[13px] border-l-transparent border-r-[13px] border-r-transparent border-t-[20px] border-t-customBlue"></span>
                  {/* Arrow */}
                </>
              )}
              <span className="">
                {isCurrent && (
                  <FaCircle
                    className="text-white border-4 z-10 relative border-customBlue rounded-full"
                    size={20}
                  />
                )}
                {!isCurrent && (
                  <span className="mt-2 text-sm h-[20px] text-gray-500">
                    {status}
                  </span>
                )}
              </span>
            </div>

            {/* Progress Line */}
            {/* {index < statuses.length - 1 && ( */}
            <div
              className={`${
                isCurrent ? "w-full" : "w-16"
              } h-4 mt-2 rounded-full relative ${
                isPrevious || isCurrent ? "bg-blue-500" : "bg-blue-100"
              }`}
            >
              {isCurrent && (
                <img
                  src={bgImg}
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  alt="image not found"
                />
              )}
            </div>

            {/* )} */}

            {/* Status Label */}
          </div>
        );
      })}
    </div>
  );
};

export default Steppers;
